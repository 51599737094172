<template>
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7 position-relative">
          ILLUSTRATION : {{ illustration.title }}
        </v-card-title>

        <!-- login form -->
        <template>
          <v-row>
            <v-col cols="12" lg="8">

              <v-img max-height="100%"
                     :src="illustration.src"
                     :lazy-src="illustration.src_270"
                     contain
                     class="grey lighten-2 align-end"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>


            </v-col>
            <v-col cols="12" lg="4">
              <v-card>

                <v-card-actions class="pa-1">
                  <v-list-item
                    class="grow pa-1"
                    dense
                  >
                    <v-list-item-avatar color="grey darken-3">
                      <v-img
                        v-if="illustration.user.avatar"
                        contain
                        class="elevation-6"
                        alt=""
                        :src="require('@/assets/images/avatars/'+illustration.user.avatar)"
                      ></v-img>
                      <v-img
                        v-else
                        contain
                        class="elevation-6"
                        alt=""
                        :src="require('@/assets/images/avatars/logo.png')"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="">
                        <router-link :to="'/profile/'+illustration.user.id"><b>{{ illustration.user.username }}</b>
                        </router-link>
                      </v-list-item-title>
                      <v-list-item-subtitle><duration :last-date="illustration.created_at"></duration></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-row
                      align="center"
                      justify="end"
                    >
                      <!--
                                          <template v-if="user.id != illustration.user.id">
                                            <follow-toggle-component
                                              v-if="post.user.following !== 1"
                                              :status="post.user.following"
                                              :user_id="parseInt(post.user.id)"
                                              :current_user_id="parseInt(user.id)"
                                              @initlogin="()=>$emit('initlogin')"
                                              @following="following"
                                            ></follow-toggle-component>

                                          </template>-->

                      <v-menu
                        bottom
                        left
                        offset-x
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mr-1">
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item color="error">
                            <v-list-item-title>Signaler la publication</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-list-item>
                </v-card-actions>
                <v-card-text>
                  <h3 >{{ illustration.title }}</h3>
                  <p>{{illustration.description}}</p>
                </v-card-text>

                <post-illustration-component class=" social-post"
                                :key="illustration.post_id"
                               :quick_comment="false"
                               :limit="15"
                               :post-item="illustration.post"
                               :user="user"
                >
                  <template #share >
                    <v-btn small @click="share" class="v-btn" >
                      <v-icon>{{icons.mdiShareVariant}}</v-icon>
                      <span class="hidden-sm-only">Partager</span>
                    </v-btn>
                  </template>
                </post-illustration-component>

              </v-card>
              <v-divider></v-divider>
              <v-row dense >
                <v-col
                  v-for="(item, n) in galleryll.listEntity"
                  :key="n"
                  class="d-flex child-flex"
                  cols="4" lg="3"
                >
                  <v-img
                    :src="item.src_270"
                    :lazy-src="require('@/assets/images/holder.jpg')"
                    aspect-ratio="1" contain
                    class="grey lighten-2 align-end"
                    @click="illustration = item; dialog.preview = true"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </template>
      </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiShareVariant,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiDownload
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import WalletRefillComponent from '@/components/WalletRefillComponent'
import CommentComponent from "@/components/CommentComponent.vue";
import {useRouter} from "@/utils";
import UserAvatar from "@/components/UserAvatar.vue";
import Duration from "@/components/Duration.vue";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import PostIllustrationComponent from "@/components/PostIllustrationComponent.vue";

export default {
  components: {
    PostIllustrationComponent,
    PostComponent, FollowToggleComponent, Duration, UserAvatar, CommentComponent},
  setup() {

    const {router} = useRouter()
    const route = router.currentRoute
    const {id} = route.params

    const user = Drequest.getUser()
    const galleryll = ref({next:1, listEntity:[]})
    const loaders = ref({download:false, loading: true})
    const dialog = ref({preview:false})
    const illustration = ref({ })

    const init = () => {
      loaders.value.loading = true
      Drequest.api('detail.illustration?jsonmodel=detail&id='+id).get(response => {
          console.log(response)
          loaders.value.loading = false
          illustration.value = response.illustration
        })

      Drequest.api('lazyloading.illustration?dfilters=on&dsort=id desc')
        .param({
          'next': galleryll.value.next,
          'per_page': 24,
          'id:neq': id,
        }).get(response => {
          console.log(response)
          loaders.value.loading = false
          galleryll.value = response
        })
    }
    init()
// Using fetch
    const downloadImage  = async (item) => {
      loaders.value.download = true
      /*const image = await fetch(item.uploaddir+''+item.image)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)*/

      const link = document.createElement('a')
      link.href = item.uploaddir+''+item.image
      link.download = item.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      loaders.value.download = false
    }
    const share = (item, title = 'Web Share API Draft', text = 'Take a look at this spec!') => {
      if (navigator.share) {
        navigator.share({
          title: '3AG Edition',
          text: 'Retrouvez encore plus d image sur la plateforme reader'
            +'https://reader.3ag-edition.com/gallery',
          url: item.src_270,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
      }
    }
    return {
      downloadImage,
      share,

      galleryll,
      loaders,
      dialog,
      illustration,
      user,

      icons: {
        mdiClose,
        mdiDownload,
        mdiEyeOutline,
        mdiArrowRight,
        mdiShareVariant,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
