<template>
  <div class=" pt-3">
    <v-card
      class="mb-3 pb-3 mx-auto" elevation="0"
      max-width="600"
    >

      <v-card-actions class=" pb-0">
        <v-btn
          :loading="loader.like"
          icon
          @click="toggleKola"
        >
          <v-icon v-if="post.liked" color="primary">{{ icons.mdiHeart }}</v-icon>
          <v-icon v-else>{{ icons.mdiHeartOutline }}</v-icon>
        </v-btn>
        <v-chip @click="$emit('postKola', post)" v-if="post.nbkola>0">
          {{ post.nbkola }}
        </v-chip>
        <v-spacer></v-spacer>

        <v-btn text @click="$emit('commentPost', post, index)" rounded >
          <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
          <span class="hidden-xs-only">Commentaires</span>
        </v-btn>
        <v-spacer></v-spacer>

        <slot name="share"></slot>
<!--        <v-btn small @click="share" class="v-btn" >
          <v-icon>{{icons.mdiShareVariant}}</v-icon>
          <span class="hidden-sm-only">Partager</span>
        </v-btn>-->
      </v-card-actions>

      <template v-if="quick_comment">
        <comment-items-component
          :quick_comment="true"
          :limit="limit"
          :user="user"
          :post_id="(post.id)"
          :nbcomment="(post.nbcomment)"
          @initlogin="()=>$emit('initlogin')"
        ></comment-items-component>
        <v-card-subtitle class="mt-3 text-center" @click="$emit('commentPost', post, index)">
          <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
          Laissez un commentaire
        </v-card-subtitle>
      </template>
      <comment-component v-else
                         :limit="limit"
                         :user="user"
                         :post_id="(post.id)"
                         :nbcomment="post.nbcomment"
                         @initlogin="()=>$emit('initlogin')"
      ></comment-component>


    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiWhatsapp,
  mdiFacebook,
  mdiPinterest,
  mdiReddit,
  mdiFacebookMessenger,
  mdiTwitter,
  mdiTelegram,
  mdiDotsVertical,
  mdiCommentOutline,
  mdiShareVariant,
  mdiHeartOutline,
  mdiHeart,
  mdiMinus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiShareVariantOutline,
  mdiClose,
  mdiCodeTags,
  mdiContentCopy,
  mdiCircle,
  mdiCircleOutline,
  mdiCollapseAllOutline,
  mdiArrowUpBoldOutline,
  mdiArrangeSendBackward,
  mdiAccountGroupOutline,
  mdiFolderAlert,
  mdiFileImageOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'

import CommentComponent from '@/components/CommentComponent'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ShareComponent from '@/components/ShareComponent'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import CommentItemsComponent from "@/components/CommentItemsComponent.vue";
import Duration from "@/components/Duration.vue";
import ReadMore from "@/components/ReadMore.vue";
// import { ShareFacebook, ShareTwitter } from 'vue-share-social'

export default {
  components: {
    ReadMore,
    Duration,
    CommentItemsComponent,
    FollowToggleComponent,
    ShareComponent,
    CommentComponent,
    // ShareFacebook,
    // ShareTwitter
  },
  props: {
    postItem: Object,
    user: Object,
    limit: Number,
    index: Number,
    quick_comment: {
      type: Boolean,
      default: () => true,
    },
    comicbook: {
      type: Object | null,
      default: () => null,
    },
    loadreply: {
      type: Boolean,
      default: () => false,
    },
  },
  //composition api
  setup(props, {emit}) {

    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      like: false,
      dialog: false,
    })

    const post = ref(JSON.parse(JSON.stringify(props.postItem)))
    const linkshare = `/activity/${post.value.id}`
    const toggleKola = () => {
      if (props.user.id) {
        loader.value.like = true
        Drequest.toggleKola('post', post.value.id, post.value.liked)
          .post(response => {
            console.log(response)
            loader.value.like = false
            post.value.liked = !post.value.liked
            post.value.nbkola = response.nbkola
          })
      } else {
        alert('Vous devez etre connecte pour liker')
      }
    }

    const deletePost = () => {
      if (!confirm('Cette publication va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`post.delete?id=${post.value.id}`)
        .get(response => {
          console.log(response)
          alert(response.detail)
          emit('deleted', props.index)
        })
        .fail(e => {
          console.log(e)
          alert('Oops! Il y a un probleme de connexion.')
        })
    }

    const iteration = ref(0)
    const nextslide = (i) => {
      console.log(i)
      iteration.value = i
    }
    const following = (response) => {
      console.log(response)
      window.location.href = '/profile/' + post.value.user.id
    }
    return {
      nextslide,
      toggleKola,
      deletePost,
      following,

      post,
      linkshare,
      loader,
      iteration,

      icons: {
        mdiAccountGroupOutline,
        mdiFolderAlert,
        mdiFileImageOutline,
        mdiClose,
        mdiDotsVertical,
        mdiCommentOutline,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiMinus,
        mdiShareVariantOutline,
        mdiCodeTags,
        mdiContentCopy,
        mdiCircle,
        mdiCircleOutline,
        mdiCollapseAllOutline,
        mdiWhatsapp,
        mdiFacebook,
        mdiPinterest,
        mdiReddit,
        mdiFacebookMessenger,
        mdiTwitter,
        mdiTelegram,
        mdiArrowUpBoldOutline,
        mdiArrangeSendBackward,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.share {
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 14px 0px;
}

.share span {
  margin-top: 10px;
}
</style>
